import React, { useState } from 'react';

const Test = () => {
  const [count, setCount] = useState(0);

  return (
    <div style={{
      padding: '20px',
      maxWidth: '800px',
      margin: '0 auto',
      textAlign: 'center'
    }}>
      <h1 style={{
        color: '#333',
        marginBottom: '20px'
      }}>
        테스트 페이지
      </h1>
      
      <div style={{
        backgroundColor: '#f5f5f5',
        padding: '20px',
        borderRadius: '8px',
        marginBottom: '20px'
      }}>
        <p style={{ fontSize: '18px', marginBottom: '10px' }}>
          현재 카운트: {count}
        </p>
        
        <button
          onClick={() => setCount(count + 1)}
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            marginRight: '10px'
          }}
        >
          증가
        </button>
        
        <button
          onClick={() => setCount(count - 1)}
          style={{
            backgroundColor: '#f44336',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          감소
        </button>
      </div>

      <div style={{
        backgroundColor: '#e3f2fd',
        padding: '15px',
        borderRadius: '8px'
      }}>
        <p>이 페이지는 라우팅 테스트를 위한 페이지입니다.</p>
        <p>정상적으로 보인다면 라우팅이 제대로 작동하는 것입니다.</p>
      </div>
    </div>
  );
};

export default Test;