import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import '../css/cardnews.css';

const CardNewsGenerator = ({ messages, generatedImages }) => {
  const cardRefs = useRef([]);
  const [loadedImages, setLoadedImages] = useState({});

  // 이미지 로드 완료 처리
  const handleImageLoad = (index) => {
    setLoadedImages(prev => ({
      ...prev,
      [index]: true
    }));
  };

  const downloadCard = async (index) => {
    try {
      // 이미지가 로드되었는지 확인
      if (!loadedImages[index]) {
        alert('이미지가 아직 로드되지 않았습니다. 잠시 후 다시 시도해주세요.');
        return;
      }

      const cardElement = cardRefs.current[index];
      const canvas = await html2canvas(cardElement, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        backgroundColor: '#ffffff',
        logging: true,
        imageTimeout: 0
      });
      
      const link = document.createElement('a');
      link.download = `card-news-${index + 1}.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();
    } catch (error) {
      console.error('Error downloading card:', error);
      alert('카드 다운로드 중 오류가 발생했습니다.');
    }
  };

  const downloadAllCards = async () => {
    try {
      // 모든 이미지가 로드되었는지 확인
      const allImagesLoaded = messages.every((_, index) => loadedImages[index]);
      if (!allImagesLoaded) {
        alert('일부 이미지가 아직 로드되지 않았습니다. 잠시 후 다시 시도해주세요.');
        return;
      }

      for (let i = 0; i < messages.length; i++) {
        await downloadCard(i);
        // 다운로드 간 약간의 딜레이
        if (i < messages.length - 1) {
          await new Promise(resolve => setTimeout(resolve, 500));
        }
      }
    } catch (error) {
      console.error('Error downloading all cards:', error);
      alert('카드 다운로드 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="card-news-container">

      {/* <div className="card-news-controls">
              <button 
                onClick={downloadAllCards}
                className="download-all-button"
              >
                전체 카드뉴스 다운로드
              </button>
            </div> */}

      <div className="card-news-grid">
        {messages.map((message, index) => (
          <div key={index} className="card-news-wrapper">
            <div 
              className="card-news-item"
              ref={el => cardRefs.current[index] = el}
            >
              <div className="card-news-content">
                <div className="card-text">
                  <div className="card-title-db">{message.title}</div>
                  <div className="card-subtitle-db">{message.subtitle}</div>
                  <div className="card-image-db">
                    {generatedImages[index] && (
                      <>
                        <img 
                          src={generatedImages[index]} 
                          alt={`Card ${index + 1}`}
                          className="generated-image"
                          crossOrigin="anonymous"
                          onLoad={() => handleImageLoad(index)}
                          onError={(e) => {
                            console.error('Image load error:', e);
                            e.target.onerror = null;
                            // 필요한 경우 placeholder 이미지로 대체
                            // e.target.src = '/img/placeholder.png';
                          }}
                          style={{
                            visibility: loadedImages[index] ? 'visible' : 'hidden'
                          }}
                        />
                        {!loadedImages[index] && (
                          <div className="image-loading">이미지 로딩 중...</div>
                        )}
                      </>
                    )}
                  </div>
                  <p className="card-description-db">{message.description}</p>
                </div>

                <div className="card-footer">
                  <img 
                    src="/img/logo.png" 
                    alt="Company Logo" 
                    className="company-logo-db"
                    crossOrigin="anonymous"
                  />
                  <span>심의번호 : DB2024-X12345</span>
                </div>
              </div>
            </div>

            <div className="download-button-wrapper">
              <button 
                onClick={() => downloadCard(index)}
                className="download-button"
                disabled={!loadedImages[index]}
              >
                {loadedImages[index] ? 
                  `카드 ${index + 1} 다운로드` : 
                  '이미지 로딩 중...'
                }
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="card-news-controls">
        <button 
          onClick={downloadAllCards}
          className="download-all-button"
        >
          전체 카드뉴스 다운로드
        </button>
      </div>

    </div>
  );
};

export default CardNewsGenerator;