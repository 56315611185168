import React, { useState, useCallback, useRef, useEffect } from 'react';
import io from 'socket.io-client';
import '../css/card_demo.css';
import axios from 'axios';
import CardNewsGenerator from './CardNewsGenerator';

const apiUrl = process.env.REACT_APP_API_URL;

// 페르소나 설정을 위한 데이터 구조 정의
const DATA = {
  // 성별 옵션
  genderOptions: [
    { value: 'male', label: '남성' },
    { value: 'female', label: '여성' }
  ],
  
  // 연령대 옵션
  ageOptions: [
    { value: '30', label: '30대' },
    { value: '40', label: '40대' },
    { value: '50', label: '50대' },
    { value: '60', label: '60대' }
  ],
  
  // 시나리오 옵션
  scenarioOptions: [
    { value: 'christmas', label: '크리스마스 안부 인사' },
    { value: 'car_care_spring', label: '봄철 차량관리 안내' },
    { value: 'car_care_summer', label: '여름철 차량관리 안내' },
    { value: 'car_care_winter', label: '겨울철 차량관리 안내' },
    { value: 'new_year_safe_driving', label: '새해 안부 인사(안전운전)'},
    { value: 'age_healthcare_3040', label: '3040 건강관리'},
    { value: 'age_healthcare_50', label: '50대 건강관리'},
    { value: 'age_healthcare_60', label: '60대 건강관리'},
    { value: 'pet_care', label: '반려동물 케어'},
    { value: 'car_care_01', label: '1월달 차량 관리 안내'},
    { value: 'car_care_02', label: '2월달 차량 관리 안내'},
    { value: 'car_care_03', label: '3월달 차량 관리 안내'},
    { value: 'car_care_04', label: '4월달 차량 관리 안내'},
    { value: 'car_care_05', label: '5월달 차량 관리 안내'},
    { value: 'car_care_06', label: '6월달 차량 관리 안내'},
    { value: 'car_care_07', label: '7월달 차량 관리 안내'},
    { value: 'car_care_08', label: '8월달 차량 관리 안내'},
    { value: 'car_care_09', label: '9월달 차량 관리 안내'},
    { value: 'car_care_10', label: '10월달 차량 관리 안내'},
    { value: 'car_care_11', label: '11월달 차량 관리 안내'},
    { value: 'car_care_12', label: '12월달 차량 관리 안내'},
  ]
};

// 이미지 모달 컴포넌트
const Modal = ({ imageUrl, onClose }) => {
  // 모달 외부 클릭 시 닫기 처리
  const handleModalClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleModalClick}>
      <div className="modal-content">
        <img src={imageUrl} alt="확대된 이미지" className="modal-image" />
      </div>
    </div>
  );
};

const CardNews = () => {
  // 상태 관리
  const [selectedCard, setSelectedCard] = useState(null);        // 선택된 카드 이미지
  const [gender, setGender] = useState('');                      // 선택된 성별
  const [age, setAge] = useState('');                           // 선택된 연령대
  const [scenario, setScenario] = useState('');                 // 선택된 시나리오
  const [isGenerating, setIsGenerating] = useState(false);      // 텍스트 생성 중 상태
  const [messages, setMessages] = useState([]);                  // 생성된 메시지 목록
  const [textProcessSteps, setTextProcessSteps] = useState([]); // 텍스트 생성 프로세스 단계
  const [isGeneratingImages, setIsGeneratingImages] = useState(false); // 이미지 생성 중 상태
  const [imageError, setImageError] = useState(null);           // 이미지 생성 오류
  const [generatedImages, setGeneratedImages] = useState([]);   // 생성된 이미지 목록
  const [processStepImg, setProcessStepImg] = useState([]);     // 이미지 생성 프로세스 단계

  // refs for scrolling
  const messageRef = useRef(null);
  const imageRef = useRef(null);

  // 스크롤 헬퍼 함수
  const scrollToBottom = (ref) => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  };

  // 메시지나 이미지가 추가될 때 자동 스크롤
  useEffect(() => {
    if (messages.length > 0) {
      setTimeout(() => scrollToBottom(messageRef), 100);
    }
  }, [messages]);
  
  useEffect(() => {
    if (generatedImages.length > 0) {
      setTimeout(() => scrollToBottom(imageRef), 100);
    }
  }, [generatedImages]);

  // 텍스트 생성 함수
  const handleTextGeneration = useCallback(async () => {
    if (!gender || !age || !scenario) return;

    // 프로세스 단계 정의
    const processSteps = [
      { id: 1, title: '뉴스 데이터 반영', status: 'pending' },
      { id: 2, title: 'AI 텍스트 생성', status: 'pending' },
      { id: 3, title: '컨텐츠 검증', status: 'pending' },
      { id: 4, title: '민감어휘 필터링', status: 'pending' }
    ];

    try {
      // 초기 상태 설정
      setIsGenerating(true);
      setTextProcessSteps([]);
      setMessages([]);

      // API 호출 시작
      const apiCall = axios.post(`${apiUrl}/persona-content-gen-api/generate_text`, {
        age: age,
        gender: gender,
        scenario: scenario
      });

      // 프로세스 단계 순차적 표시
      for (let i = 0; i < processSteps.length; i++) {
        const currentSteps = processSteps.slice(0, i + 1).map(step => ({
          ...step,
          status: 'completed'
        }));
        
        setTextProcessSteps(currentSteps);
        await new Promise(resolve => setTimeout(resolve, 4500));
      }

      // API 응답 처리
      const response = await apiCall;
      if (response.data.versions) {
        for (let i = 0; i < response.data.versions.length; i++) {
          await new Promise(resolve => setTimeout(resolve, 800));
          setMessages(prev => {
            const newMessages = [...prev, response.data.versions[i]];
            setTimeout(() => scrollToBottom(messageRef), 100);
            return newMessages;
          });
        }
      }

    } catch (error) {
      console.error('텍스트 생성 에러:', error);
      alert('텍스트 생성 중 오류가 발생했습니다.');
    } finally {
      setIsGenerating(false);
    }
  }, [gender, age, scenario]);

  // 이미지 생성 함수 (DALLE 사용)
  const handleImageGenerationDalle = async () => {
    if (!messages.length) return;
    
    // 이미지 생성 프로세스 단계 정의
    const processSteps = [
      { id: 1, title: '프롬프트 구성', status: 'pending' },
      { id: 2, title: '스타일 지정', status: 'pending' },
      { id: 3, title: '이미지 생성', status: 'pending' },
    ];

    try {
      // 초기 상태 설정
      setIsGeneratingImages(true);
      setImageError(null);
      setGeneratedImages([]);
      setProcessStepImg([]);

      // API 호출 준비
      const apiCall = axios.post(`${apiUrl}/persona-content-gen-api/gen_image_dalle`, {
        persona: { gender, age, scenario },
        messages: messages.map(msg => ({
          title: msg.title,
          subtitle: msg.subtitle,
          description: msg.description
        }))
      }, {
        timeout: 600000 // 10분 타임아웃
      });

      // 프로세스 단계 순차적 표시
      for (let i = 0; i < processSteps.length; i++) {
        const currentSteps = processSteps.slice(0, i + 1).map(step => ({
          ...step,
          status: 'completed'
        }));
        
        setProcessStepImg(currentSteps);
        await new Promise(resolve => setTimeout(resolve, 7000));
      }

      // API 응답 처리
      const response = await apiCall;
      if (response.data.status === 200) {
        const imagePaths = response.data.image_paths || response.data.images || [];
        
        if (imagePaths.length > 0) {
          // 이미지 순차적 표시
          for (let i = 0; i < imagePaths.length; i++) {
            await new Promise(resolve => setTimeout(resolve, 500));
            setGeneratedImages(prev => [...prev, imagePaths[i]]);
            scrollToBottom(imageRef);
          }
        } else {
          setImageError('이미지를 받지 못했습니다.');
        }
      } else {
        setImageError('서버 응답이 올바르지 않습니다.');
      }
      
    } catch (error) {
      console.error('Error:', error);
      setImageError(error.response?.data?.message || '이미지 생성 중 오류가 발생했습니다.');
    } finally {
      setIsGeneratingImages(false);
    }
  };

  // 옵션 선택기 컴포넌트
  const OptionSelector = ({ label, options, value, onChange }) => (
    <div className="persona-section">
      <h4>{label}</h4>
      <select 
        value={value} 
        onChange={(e) => onChange(e.target.value)}
        className="persona-dropdown"
      >
        <option value="">선택하세요</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );

  // 메인 렌더링
  return (
    <div className="container">
      <header className="App-header">
        <h1>페르소나 기반 고객 맞춤형 AI 컨텐츠 생성 솔루션</h1>
      </header>

      <div className="App-columns">
        {/* 페르소나 설정 섹션 */}
        <div className="App-column">
          <h2>Process 1</h2>
          <h3>페르소나 설정</h3>
          
          <div className="column-content">
            <OptionSelector 
              label="성별" 
              options={DATA.genderOptions} 
              value={gender} 
              onChange={setGender}
            />
            <OptionSelector 
              label="연령대" 
              options={DATA.ageOptions} 
              value={age} 
              onChange={setAge}
            />
            <OptionSelector 
              label="시나리오" 
              options={DATA.scenarioOptions} 
              value={scenario} 
              onChange={setScenario}
            />
          </div>

          <button
            onClick={handleTextGeneration}
            disabled={!gender || !age || !scenario || isGenerating}
            className="action-button"
          >
            텍스트 생성하기
          </button>
        </div>

        {/* 텍스트 생성 결과 섹션 */}
        <div className="App-column">
          <h2>Process 2</h2>
          <h3 className={isGenerating ? 'processing-title animate-pulse' : ''}>
            {isGenerating ? 'AI 텍스트 생성 중...' : 'AI 텍스트 생성'}
          </h3>
          
          <div className="column-content">
            {/* 프로세스 단계 표시 */}
            <div className="process-steps">
              {textProcessSteps.map((step, index) => (
                <div key={index} className={`process-step ${step.status === 'completed' ? 'completed' : ''}`}>
                  <div className="step-number">{step.id}</div>
                  <div className="step-title">{step.title}</div>
                  <div className="step-status">
                    {step.status === 'completed' ? '완료' : '대기'}
                  </div>
                </div>
              ))}
            </div>
            
            {/* 생성된 메시지 표시 */}
            <div className="text-result-container message-container" ref={messageRef}>
              {messages.map((message, index) => (
                <div key={index} className="message-section fade-in">
                  <h4 className="message-number">메시지 {index + 1}</h4>
                  <div className="message-content">
                    <p><strong>제목:</strong> {message.title}</p>
                    <p><strong>부제목:</strong> {message.subtitle}</p>
                    <p><strong>설명문:</strong> {message.description}</p>
                  </div>
                  {index < messages.length - 1 && <hr />}
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={handleImageGenerationDalle}
            disabled={messages.length === 0 || isGeneratingImages}
            className="action-button"
          >
            이미지 생성하기
          </button>
        </div>

        {/* 이미지 생성 결과 섹션 */}
        <div className="App-column">
          <h2>Process 3</h2>
          <h3 className={isGeneratingImages ? 'processing-title' : ''}>
            {isGeneratingImages ? 'AI 이미지 생성 중' : 'AI 이미지 생성'}
          </h3>

          {/* 이미지 생성 프로세스 단계 */}
          <div className="process-steps">
            {processStepImg.map((step, index) => (
              <div key={index} className={`process-step ${step.status === 'completed' ? 'completed' : ''}`}>
                <div className="step-number">{step.id}</div>
                <div className="step-title">{step.title}</div>
                <div className="step-status">
                  {step.status === 'completed' ? '완료' : '대기'}
                </div>
              </div>
            ))}
          </div>

          {/* 에러 메시지 표시 */}
          {imageError && (
            <div className="error-message">
              {imageError}
            </div>
          )}

          {/*생성된 이미지 표시 */}
          <div className="column-content">
            <div className="img-result-container" ref={imageRef}>
              <div className="image-grid">
                {generatedImages.map((imageUrl, index) => {
                  // 페이지 번호와 하위 번호 계산
                  const pageNum = Math.floor(index / 4) + 1;
                  const subNum = (index % 4) + 1;
                  
                  return (
                    <div key={index} className="image-item">
                      <img
                        src={imageUrl}
                        alt={`Generated ${pageNum}-${subNum}`}
                        className="grid-image"
                        onClick={() => setSelectedCard(imageUrl)}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/images/placeholder.jpg';
                        }}
                      />
                      <span className="image-number">{`${pageNum}-${subNum}`}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* 카드뉴스 생성 섹션 */}
        <div className="App-column">
          <h2>Process 4</h2>
          <CardNewsGenerator 
            messages={messages} 
            generatedImages={generatedImages} 
          />
        </div>
      </div>

      {/* 이미지 확대 모달 */}
      {selectedCard && (
        <Modal
          imageUrl={selectedCard}
          onClose={() => setSelectedCard(null)}
        />
      )}
    </div>
  );
};

export default CardNews;