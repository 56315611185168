// import React from 'react';

// const LoadingOverlay = ({ isLoading }) => {
//   if (!isLoading) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
//       <img src="/img/loading.gif" alt="Loading..." className="w-24 h-24" />
//     </div>
//   );
// };

// export default LoadingOverlay;

import React from 'react';
import '../css/loading-overlay.css';

const LoadingOverlay = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="loading-overlay">
      <div className="loading-content">
        <img src="/img/loading.gif" alt="Loading..." />
      </div>
    </div>
  );
};

export default LoadingOverlay;