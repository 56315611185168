import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import Client_root from './pages/client_root';
import Client_demo from './pages/client_demo';
import Card_news from './pages/card_news';
import Card_dashboard from './pages/card_dashboard';

// const apiUrl = process.env.REACT_APP_API_URL;
// const loadingGifUrl = '/img/spinner_tutor_01.gif';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Client_root />} />
          <Route path="/client_demo" element={<Client_demo />} />
          <Route path="/card_news" element={<Card_news />} />
          <Route path="/card_dashboard" element={<Card_dashboard />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;