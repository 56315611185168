import React, { useState, useEffect } from 'react';
import { Edit2, Save, Link, PlusCircle, Trash2 } from 'lucide-react';
import axios from 'axios';
import '../css/scenario_management.css';
import LoadingOverlay from './LoadingOverlay';

const ScenarioManagement = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [scenarios, setScenarios] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('news');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [createFormData, setCreateFormData] = useState({
    scenario_case_ko: '',
    source: '[]'
  });
  const [editMode, setEditMode] = useState({
    news_data: false,
    eg_text: false,
    common_prompt: false
  });
  const [editFormData, setEditFormData] = useState({
    news_data: '',
    eg_text: {
      title: '',
      subtitle: '',
      description: '',
      image_shape: 'square'
    },
    common_prompt: {
      ai_role: '',
      response_format: '',
      content_purpose: '',
      content_rules: ''
    }
  });

  useEffect(() => {
    fetchScenarios();
  }, []);

  const fetchScenarios = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/persona-content-gen-api/api/scenarios`);
      setScenarios(response.data);
      if (response.data.length > 0 && !selectedScenario) {
        setSelectedScenario(response.data[0]);
        let parsedEgText = {
          title: '',
          subtitle: '',
          description: '',
          image_shape: 'square'
        };
        
        let parsedCommonPrompt = {
          ai_role: '',
          response_format: '',
          content_purpose: '',
          content_rules: ''
        };
        
        try {
          if (response.data[0].eg_text) {
            parsedEgText = typeof response.data[0].eg_text === 'string' 
              ? JSON.parse(response.data[0].eg_text)
              : response.data[0].eg_text;
          }
          if (response.data[0].common_prompt) {
            parsedCommonPrompt = parsePromptString(response.data[0].common_prompt);
          }
        } catch (err) {
          console.error('Error parsing data:', err);
        }

        setEditFormData({
          news_data: response.data[0].news_data,
          eg_text: parsedEgText,
          common_prompt: parsedCommonPrompt
        });
      }
    } catch (err) {
      setError('시나리오 데이터를 불러오는데 실패했습니다.');
      console.error('Error fetching scenarios:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateScenario = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(`${apiUrl}/persona-content-gen-api/api/scenarios`, createFormData);
      await fetchScenarios();
      setShowCreateForm(false);
      setCreateFormData({
        scenario_case_ko: '',
        scenario_case_en: '',
        news_data: '',
        eg_text: '',
        common_prompt: '',
        source: '[]'
      });
    } catch (err) {
      alert('시나리오 생성에 실패했습니다.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteScenario = async (scenarioId) => {
    if (!window.confirm('정말 이 시나리오를 삭제하시겠습니까?')) return;
    
    try {
      await axios.delete(`${apiUrl}/persona-content-gen-api/api/scenarios/${scenarioId}`);
      if (selectedScenario?.scenario_id === scenarioId) {
        setSelectedScenario(null);
      }
      await fetchScenarios();
    } catch (err) {
      alert('시나리오 삭제에 실패했습니다.');
      console.error('Error deleting scenario:', err);
    }
  };

  const parsePromptString = (promptString) => {
    const sections = {
      ai_role: '',
      response_format: '',
      content_purpose: '',
      content_rules: ''
    };

    if (!promptString) return sections;

    const aiRoleMatch = promptString.match(/\[AI Agent 역할\]([\s\S]*?)(?=\[응답 형식\]|$)/);
    const responseFormatMatch = promptString.match(/\[응답 형식\]([\s\S]*?)(?=\[컨텐츠 생성의 목적\]|$)/);
    const contentPurposeMatch = promptString.match(/\[컨텐츠 생성의 목적\]([\s\S]*?)(?=\[컨텐츠 생성 규칙\]|$)/);
    const contentRulesMatch = promptString.match(/\[컨텐츠 생성 규칙\]([\s\S]*?)$/);

    if (aiRoleMatch) sections.ai_role = aiRoleMatch[1].trim();
    if (responseFormatMatch) sections.response_format = responseFormatMatch[1].trim();
    if (contentPurposeMatch) sections.content_purpose = contentPurposeMatch[1].trim();
    if (contentRulesMatch) sections.content_rules = contentRulesMatch[1].trim();

    return sections;
  };

  const formatPromptString = (sections) => {
    return `[AI Agent 역할]\n${sections.ai_role}\n\n[응답 형식]\n${sections.response_format}\n\n[컨텐츠 생성의 목적]\n${sections.content_purpose}\n\n[컨텐츠 생성 규칙]\n${sections.content_rules}`;
  };

  const handleScenarioSelect = (scenario) => {
    console.log('Selected Scenario:', scenario);  // 선택된 시나리오 전체 데이터
    console.log('Common Prompt Raw:', scenario.common_prompt);  // 원본 common_prompt 데이터
    
    setSelectedScenario(scenario);
    let parsedEgText = {
      title: '',
      subtitle: '',
      description: '',
      image_shape: 'square'
    };
    
    let parsedCommonPrompt = {
      ai_role: '',
      response_format: '',
      content_purpose: '',
      content_rules: ''
    };
    
    try {
      if (scenario.eg_text) {
        parsedEgText = typeof scenario.eg_text === 'string' 
          ? JSON.parse(scenario.eg_text)
          : scenario.eg_text;
      }
      
      if (scenario.common_prompt) {
        console.log('Common Prompt Type:', typeof scenario.common_prompt);  // 데이터 타입 확인
        
        // common_prompt가 이미 객체인 경우
        if (typeof scenario.common_prompt === 'object' && 
            !Array.isArray(scenario.common_prompt) && 
            scenario.common_prompt !== null) {
          console.log('Common Prompt is Object:', scenario.common_prompt);  // 객체인 경우
          parsedCommonPrompt = {
            ai_role: scenario.common_prompt.ai_role || '',
            response_format: scenario.common_prompt.response_format || '',
            content_purpose: scenario.common_prompt.content_purpose || '',
            content_rules: scenario.common_prompt.content_rules || ''
          };
        } 
        // common_prompt가 문자열인 경우
        else if (typeof scenario.common_prompt === 'string') {
          console.log('Common Prompt is String:', scenario.common_prompt);  // 문자열인 경우
          try {
            parsedCommonPrompt = parsePromptString(scenario.common_prompt);
            console.log('Parsed Common Prompt Result:', parsedCommonPrompt);  // 파싱 결과
          } catch (parseErr) {
            console.error('Error parsing common_prompt:', parseErr);
          }
        }
      }
    } catch (err) {
      console.error('Error in handleScenarioSelect:', err);
    }

    console.log('Final Parsed Common Prompt:', parsedCommonPrompt);  // 최종 파싱된 데이터

    setEditFormData({
      news_data: scenario.news_data,
      eg_text: parsedEgText,
      common_prompt: parsedCommonPrompt
    });
    setEditMode({
      news_data: false,
      eg_text: false,
      common_prompt: false
    });
  };

  const handleEdit = (field) => {
    console.log('Edit Mode Activated for:', field);  // 수정 모드 활성화 시
    console.log('Current Edit Form Data:', editFormData);  // 현재 편집 폼 데이터
    setEditMode(prev => ({ ...prev, [field]: true }));
  };

  const handleSave = async (field) => {
    try {
      console.log('Save Attempt for:', field);  // 저장 시도 시
      console.log('Current Edit Form Data:', editFormData);  // 저장 시점의 편집 폼 데이터
      
      let updateData;
      if (field === 'eg_text') {
        updateData = { eg_text: JSON.stringify(editFormData.eg_text) };
      } else if (field === 'common_prompt') {
        console.log('Common Prompt Before Format:', editFormData.common_prompt);  // 포맷팅 전 데이터
        if (editFormData.common_prompt.ai_role || 
            editFormData.common_prompt.response_format || 
            editFormData.common_prompt.content_purpose || 
            editFormData.common_prompt.content_rules) {
          updateData = { common_prompt: formatPromptString(editFormData.common_prompt) };
          console.log('Formatted Common Prompt:', updateData.common_prompt);  // 포맷팅된 데이터
        } else {
          console.log('Empty Common Prompt, Skipping Update');  // 빈 데이터인 경우
          return;
        }
      } else {
        updateData = { [field]: editFormData[field] };
      }

      await axios.patch(`${apiUrl}/persona-content-gen-api/api/scenarios/${selectedScenario.scenario_id}`, updateData);
      
      setSelectedScenario(prev => ({
        ...prev,
        [field]: field === 'eg_text' 
          ? JSON.stringify(editFormData.eg_text)
          : field === 'common_prompt'
          ? (editFormData.common_prompt.ai_role || 
             editFormData.common_prompt.response_format || 
             editFormData.common_prompt.content_purpose || 
             editFormData.common_prompt.content_rules)
            ? formatPromptString(editFormData.common_prompt)
            : prev.common_prompt
          : editFormData[field]
      }));

      setEditMode(prev => ({ ...prev, [field]: false }));
      await fetchScenarios();
    } catch (err) {
      console.error('Error in handleSave:', err);
    }
  };

  const renderSourceLinks = (sourceData) => {
    try {
      const sources = typeof sourceData === 'string' ? JSON.parse(sourceData) : sourceData;
      return sources.map((source, index) => (
        <div key={index} className="source-item">
          <a href={source.url} target="_blank" rel="noopener noreferrer" className="source-title">
            {source.title}
          </a>
          <span className="source-score">Score: {source.score.toFixed(4)}</span>
        </div>
      ));
    } catch {
      return <div className="error-message">소스 데이터 형식이 올바르지 않습니다.</div>;
    }
  };

  const renderEditableField = (field, value, label) => {
    const isEditing = editMode[field];
    return (
      <div className="editable-field">
        <div className="field-header">
          <h3>{label}</h3>
          <button
            className={`edit-button ${isEditing ? 'active' : ''}`}
            onClick={() => isEditing ? handleSave(field) : handleEdit(field)}
          >
            {isEditing ? (
              <><Save className="icon" /> 저장</>
            ) : (
              <><Edit2 className="icon" /> 수정</>
            )}
          </button>
        </div>
        <div className="field-content">
          {isEditing ? (
            field === 'eg_text' ? (
              <div className="eg-text-fields">
                <div className="form-group">
                  <label>제목</label>
                  <input
                    type="text"
                    value={editFormData.eg_text.title}
                    onChange={(e) => setEditFormData(prev => ({
                      ...prev,
                      eg_text: { ...prev.eg_text, title: e.target.value }
                    }))}
                  />
                </div>
                <div className="form-group">
                  <label>부제목</label>
                  <input
                    type="text"
                    value={editFormData.eg_text.subtitle}
                    onChange={(e) => setEditFormData(prev => ({
                      ...prev,
                      eg_text: { ...prev.eg_text, subtitle: e.target.value }
                    }))}
                  />
                </div>
                <div className="form-group">
                  <label>설명</label>
                  <textarea
                    value={editFormData.eg_text.description}
                    onChange={(e) => setEditFormData(prev => ({
                      ...prev,
                      eg_text: { ...prev.eg_text, description: e.target.value }
                    }))}
                  />
                </div>
                {/* <div className="form-group">
                  <label>이미지 형태</label>
                  <div className="image-shape-selector">
                    <button
                      type="button"
                      className={`shape-button ${editFormData.eg_text.image_shape === 'circle' ? 'active' : ''}`}
                      onClick={() => setEditFormData(prev => ({
                        ...prev,
                        eg_text: { ...prev.eg_text, image_shape: 'circle' }
                      }))}
                    >
                      원형
                    </button>
                    <button
                      type="button"
                      className={`shape-button ${editFormData.eg_text.image_shape === 'square' ? 'active' : ''}`}
                      onClick={() => setEditFormData(prev => ({
                        ...prev,
                        eg_text: { ...prev.eg_text, image_shape: 'square' }
                      }))}
                    >
                      사각형
                    </button>
                  </div>
                </div> */}
              </div>
            ) : field === 'common_prompt' ? (
              <div className="common-prompt-fields">
                <div className="form-group">
                  <label>AI Agent 역할</label>
                  <textarea
                    value={editFormData.common_prompt.ai_role}
                    onChange={(e) => setEditFormData(prev => ({
                      ...prev,
                      common_prompt: { ...prev.common_prompt, ai_role: e.target.value }
                    }))}
                  />
                </div>
                <div className="form-group">
                  <label>응답 형식</label>
                  <textarea
                    value={editFormData.common_prompt.response_format}
                    onChange={(e) => setEditFormData(prev => ({
                      ...prev,
                      common_prompt: { ...prev.common_prompt, response_format: e.target.value }
                    }))}
                  />
                </div>
                <div className="form-group">
                  <label>컨텐츠 생성의 목적</label>
                  <textarea
                    value={editFormData.common_prompt.content_purpose}
                    onChange={(e) => setEditFormData(prev => ({
                      ...prev,
                      common_prompt: { ...prev.common_prompt, content_purpose: e.target.value }
                    }))}
                  />
                </div>
                <div className="form-group">
                  <label>컨텐츠 생성 규칙</label>
                  <textarea
                    value={editFormData.common_prompt.content_rules}
                    onChange={(e) => setEditFormData(prev => ({
                      ...prev,
                      common_prompt: { ...prev.common_prompt, content_rules: e.target.value }
                    }))}
                  />
                </div>
              </div>
            ) : (
              <textarea
                value={editFormData[field]}
                onChange={(e) => setEditFormData(prev => ({ ...prev, [field]: e.target.value }))}
              />
            )
          ) : (
            <div className="content-display">
              {field === 'eg_text' ? (
                <div className="eg-text-display">
                  <h4>제목: {JSON.parse(value).title}</h4>
                  <h5>부제목: {JSON.parse(value).subtitle}</h5>
                  <p>설명: {JSON.parse(value).description}</p>
                  {/* <div className="image-preview">
                    <img 
                      src="/path/to/your/image.jpg" 
                      alt="미리보기" 
                      className={`generated-image ${JSON.parse(value).image_shape}`}
                    />
                  </div> */}
                </div>
              ) : field === 'common_prompt' ? (
                <div className="common-prompt-display">
                  <div className="prompt-section">
                    <h4>AI Agent 역할</h4>
                    <p>{parsePromptString(value).ai_role}</p>
                  </div>
                  <div className="prompt-section">
                    <h4>응답 형식</h4>
                    <p>{parsePromptString(value).response_format}</p>
                  </div>
                  <div className="prompt-section">
                    <h4>컨텐츠 생성의 목적</h4>
                    <p>{parsePromptString(value).content_purpose}</p>
                  </div>
                  <div className="prompt-section">
                    <h4>컨텐츠 생성 규칙</h4>
                    <p>{parsePromptString(value).content_rules}</p>
                  </div>
                </div>
              ) : (
                value
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderCreateForm = () => {
    return (
      <div className="create-form">
        <h2>새 시나리오 생성</h2>
        <form onSubmit={handleCreateScenario}>
          <div className="form-group">
            <label>시나리오 이름</label>
            <input
              type="text"
              value={createFormData.scenario_case_ko}
              onChange={(e) => setCreateFormData(prev => ({
                ...prev,
                scenario_case_ko: e.target.value
              }))}
              required
            />
          </div>
          <div className="form-actions">
            <button type="submit" className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? '생성 중...' : '생성'}
            </button>
            <button 
              type="button" 
              className="cancel-button" 
              onClick={() => setShowCreateForm(false)}
            >
              취소
            </button>
          </div>
        </form>
      </div>
    );
  };

  if (loading) return <div className="loading">로딩 중...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="scenario-container">
      <LoadingOverlay isLoading={isSubmitting} />
      <div className="scenario-sidebar">
        <div className="sidebar-header">
          <h2>시나리오 목록</h2>
          <button 
            className="create-button"
            onClick={() => setShowCreateForm(true)}
          >
            <PlusCircle className="icon" /> 새 시나리오
          </button>
        </div>
        <div className="scenario-list">
          {scenarios.map((scenario) => (
            <div
              key={scenario.scenario_id}
              className={`scenario-item ${selectedScenario?.scenario_id === scenario.scenario_id ? 'active' : ''}`}
            >
              <div 
                className="scenario-info"
                onClick={() => handleScenarioSelect(scenario)}
              >
                <div className="scenario-name">{scenario.scenario_case_ko}</div>
                <div className="scenario-name-en">{scenario.scenario_case_en}</div>
              </div>
              <button
                className="delete-button"
                onClick={() => handleDeleteScenario(scenario.scenario_id)}
              >
                <Trash2 className="icon" />
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="content-area">
        {showCreateForm ? (
          renderCreateForm()
        ) : selectedScenario ? (
          <>
            <div className="content-header">
              <h1>{selectedScenario.scenario_case_ko}</h1>
              <p>{selectedScenario.scenario_case_en}</p>
            </div>

            <div className="tab-navigation">
              <button
                className={`tab-button ${activeTab === 'news' ? 'active' : ''}`}
                onClick={() => setActiveTab('news')}
              >
                참고 데이터
              </button>
              <button
                className={`tab-button ${activeTab === 'sources' ? 'active' : ''}`}
                onClick={() => setActiveTab('sources')}
              >
                웹 리서치 자료
              </button>
              <button
                className={`tab-button ${activeTab === 'example' ? 'active' : ''}`}
                onClick={() => setActiveTab('example')}
              >
                프롬프트 수정
              </button>
            </div>

            <div className="tab-content">
              {activeTab === 'news' && renderEditableField('news_data', selectedScenario.news_data, '카드뉴스 생성 참고 데이터')}
              {activeTab === 'sources' && (
                <div className="sources-list">
                  <h3>웹 리서치 링크</h3>
                  {renderSourceLinks(selectedScenario.source)}
                </div>
              )}
              {activeTab === 'example' && (
                <>
                  {renderEditableField('eg_text', selectedScenario.eg_text, '카드뉴스 예제 설정')}
                  {renderEditableField('common_prompt', selectedScenario.common_prompt, '콘텐츠 생성 규칙 설정')}
                </>
              )}
            </div>
          </>
        ) : (
          <div className="no-selection">시나리오를 선택해주세요</div>
        )}
      </div>
    </div>
  );
};

export default ScenarioManagement;