import React from 'react';
import '../css/ImageSelectionModal.css';  // 위의 CSS를 이 파일에 저장

const ImageSelectionModal = ({
  isOpen,
  onClose,
  currentImage,
  variations,
  selectedVariation,
  onSelect,
  onApply,
  cardId,
  cardInfo  // 추가된 prop
}) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-container">
        <div className="modal-header">
          <h2 className="modal-title">이미지 선택</h2>
          <p className="modal-subtitle">새로 생성된 이미지 중 하나를 선택하세요</p>
        </div>

        {/* <div className="current-image-section">
          <h4>현재 이미지</h4>
          <div className="current-image-wrapper">
            <img src={currentImage} alt="Current" />
          </div>
        </div> */}
        <div className="current-image-section">
          <h4>현재 이미지</h4>
          <div className="modal-content-grid">
            <div className="current-image-wrapper">
              <img src={currentImage} alt="Current" />
            </div>
            {cardInfo && (
              <div className="current-card-info">
                <h4 className="card-title">{cardInfo.title}</h4>
                <h5 className="card-subtitle">{cardInfo.subtitle}</h5>
                <p className="card-description">{cardInfo.description}</p>
              </div>
            )}
          </div>
        </div>

        <h4>새로운 이미지 선택</h4>
        <div className="variations-grid">
          {variations.map((variation, idx) => (
            <div
              key={idx}
              className={`variation-item ${selectedVariation === variation ? 'selected' : ''}`}
              onClick={() => onSelect(variation)}
            >
              <img src={variation} alt={`Variation ${idx + 1}`} />
            </div>
          ))}
        </div>

        <div className="modal-actions">
          <button
            className="modal-button cancel-button"
            onClick={onClose}
          >
            취소
          </button>
          <button
            className="modal-button apply-button"
            onClick={() => onApply(cardId)}
            disabled={!selectedVariation}
          >
            적용
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageSelectionModal;