import React, { useState, useEffect, useRef } from 'react';
import html2canvas from 'html2canvas';
import axios from 'axios';
import '../css/card_dashboard.css';
import '../css/cardnews.css';
import ImageSelectionModal from './ImageSelectionModal';
import FilterSection from './FilterSection'; 
import JSZip from 'jszip';

const CardDashboard = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const cardRefs = useRef([]);
  const [cards, setCards] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterOptions, setFilterOptions] = useState({
    scenarios: [],
    ages: [],
    genders: [],
    months: [],
    statuses: []
  });
  const [filters, setFilters] = useState({
    scenario: 'all',
    age: 'all',
    gender: 'all',
    month: 'all',
    status: 'all'
  });
  const [editData, setEditData] = useState({
    title: '',
    subtitle: '',
    description: '',
    status: '생성',
    approval_number: '',
    img_url: ''
  });

  const statusOptions = ['생성', '검토완료', '심의완료', '사용불가'];

  // 이미지 재생성 및 업데이트 관련 state
  const [regeneratingImage, setRegeneratingImage] = useState(false);
  const [imageVariations, setImageVariations] = useState([]);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);

  // isImageModalOpen state 추가
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [activeCardId, setActiveCardId] = useState(null);
  const [currentCardInfo, setCurrentCardInfo] = useState(null);
  
  const handleRegenerateImage = async (card) => {
    try {
      setRegeneratingImage(true);
      setActiveCardId(card.card_id);
      const response = await axios.post(`${apiUrl}/persona-content-gen-api/api/regenerate-image/${card.card_id}`);
      console.log("handleRegenerateImage : ", handleRegenerateImage);
      setCurrentImageUrl(response.data.current_image);
      setImageVariations(response.data.image_variations);
      setSelectedVariation(null);
      setIsImageModalOpen(true);
      // 현재 카드의 내용 정보도 함께 전달

      setCurrentCardInfo({
        title: response.data.card_txt_info.title,
        subtitle: response.data.card_txt_info.sub_title,
        description: response.data.card_txt_info.description
      });
    } catch (error) {
      console.error('Error regenerating image:', error);
      setError('이미지 재생성에 실패했습니다.');
    } finally {
      setRegeneratingImage(false);
    }
  };

  const handleSelectVariation = (imageUrl) => {
    setSelectedVariation(imageUrl);
  };

  // 뷰포트에 보이는 이미지만 로드
  const CardImage = ({ src, alt }) => {
    const [isVisible, setIsVisible] = useState(false);
    const imgRef = useRef();
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(imgRef.current);
          }
        },
        { threshold: 0.1 }
      );
  
      observer.observe(imgRef.current);
      return () => observer.disconnect();
    }, []);
  
    return (
      <div ref={imgRef} className="card-image-db">
        {isVisible ? (
          <img
            src={src}
            alt={alt}
            className="generated-image"
            crossOrigin="anonymous"
          />
        ) : (
          <div className="image-placeholder" />
        )}
      </div>
    );
  };
  
  const handleApplyVariation = async (cardId) => {
    if (!selectedVariation) return;
    
    try {
      await axios.put(`${apiUrl}/persona-content-gen-api/api/update-image/${cardId}`, {
        image_url: selectedVariation
      });
      
      // Refresh cards data
      await fetchCards();
      
      // Reset states and close modal
      setImageVariations([]);
      setSelectedVariation(null);
      setCurrentImageUrl(null);
      setIsImageModalOpen(false);  // 모달 닫기 추가
    } catch (error) {
      console.error('Error updating image:', error);
      setError('이미지 업데이트에 실패했습니다.');
    }
  };
  
  const handleCancelVariation = () => {
    setImageVariations([]);
    setSelectedVariation(null);
    setCurrentImageUrl(null);
    setIsImageModalOpen(false);
    setActiveCardId(null);  // activeCardId 리셋 추가
  };

  useEffect(() => {
    fetchCards();
  }, []);

  useEffect(() => {
    if (cards.length) {
      const options = {
        // scenario_case 대신 scenario_case_ko를 사용하도록 수정
        scenarios: [...new Set(cards.map(card => card.scenario_case_ko || card.scenario_case))],
        ages: [...new Set(cards.map(card => card.age))],
        genders: [...new Set(cards.map(card => card.gender))],
        months: [...new Set(cards.map(card => card.month))],
        statuses: [...new Set(cards.map(card => card.status))]
      };
      setFilterOptions(options);
    }
  }, [cards]);

  const fetchCards = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/persona-content-gen-api/api/cards`);
      setCards(response.data);
      setError(null);
    } catch (err) {
      setError('카드 데이터를 불러오는데 실패했습니다.');
      console.error('Error fetching cards:', err);
    } finally {
      setLoading(false);
    }
  };

  // const filteredCards = cards.filter(card => {
  //   return (filters.scenario === 'all' || card.scenario_case === filters.scenario) &&
  //          (filters.age === 'all' || card.age === filters.age) &&
  //          (filters.gender === 'all' || card.gender === filters.gender) &&
  //          (filters.month === 'all' || card.month === filters.month) &&
  //          (filters.status === 'all' || card.status === filters.status);
  // });

  const filteredCards = cards.filter(card => {
    // 시나리오 필터링 로직 수정
    const scenarioMatch = filters.scenario === 'all' || 
      card.scenario_case_ko === filters.scenario;
  
    return scenarioMatch &&
           (filters.age === 'all' || card.age === filters.age) &&
           (filters.gender === 'all' || card.gender === filters.gender) &&
           (filters.month === 'all' || card.month === filters.month) &&
           (filters.status === 'all' || card.status === filters.status);
  });

  const handleEdit = (card) => {
    setEditingId(card.card_id);
    setEditData({
      title: card.title,
      subtitle: card.subtitle,
      description: card.description,
      status: card.status,
      approval_number: card.approval_number,
      img_url: card.img_url
    });
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditData({
      title: '',
      subtitle: '',
      description: '',
      status: '',
      approval_number: '',
      img_url: ''
    });
  };

  const handleUpdate = async (cardId) => {
    try {
      await axios.put(`${apiUrl}/persona-content-gen-api/api/cards/${cardId}`, editData);
      setEditingId(null);
      await fetchCards();
      setError(null);
    } catch (err) {
      setError('카드 업데이트에 실패했습니다.');
      console.error('Error updating card:', err);
    }
  };

  const handleStatusChange = (newStatus) => {
    setEditData(prev => ({
      ...prev,
      status: newStatus
    }));
  };

  const downloadCard = async (cardElement, fileName) => {
    try {
      const canvas = await html2canvas(cardElement, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        backgroundColor: '#ffffff',
        logging: true,
        imageTimeout: 0,
        // foreignObjectRendering: true // 추가
      });
      
      const link = document.createElement('a');
      link.download = fileName;
      link.href = canvas.toDataURL('image/png');
      link.click();
    } catch (error) {
      console.error('Error downloading card:', error);
      alert('카드 다운로드 중 오류가 발생했습니다.');
    }
  };

  const handleDownloadSingle = async (cardId, index) => {
    const cardElement = cardRefs.current[index];
    if (cardElement) {
      await downloadCard(cardElement, `card-${cardId}.png`);
    }
  };

  // const handleDownloadAll = async () => {
  //   try {
  //     for (let i = 0; i < filteredCards.length; i++) {
  //       const cardElement = cardRefs.current[i];
  //       if (cardElement) {
  //         await downloadCard(cardElement, `card-${filteredCards[i].card_id}.png`);
  //         if (i < filteredCards.length - 1) {
  //           await new Promise(resolve => setTimeout(resolve, 500));
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error downloading all cards:', error);
  //     alert('일괄 다운로드 중 오류가 발생했습니다.');
  //   }
  // };

  const handleDownloadAll = async () => {
    try {
      const zip = new JSZip();
      
      for (let i = 0; i < filteredCards.length; i++) {
        const cardElement = cardRefs.current[i];
        if (cardElement) {
          const canvas = await html2canvas(cardElement, {
            scale: 2,
            useCORS: true,
            allowTaint: true,
            backgroundColor: '#ffffff',
            imageTimeout: 0,
          });
          
          const imageData = canvas.toDataURL('image/png').split(',')[1];
          zip.file(`card-${filteredCards[i].card_id}.png`, imageData, {base64: true});
        }
      }
      
      const zipContent = await zip.generateAsync({type: 'blob'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(zipContent);
      link.download = 'cardnews.zip';
      link.click();
      
    } catch (error) {
      console.error('Error downloading cards:', error);
      alert('일괄 다운로드 중 오류가 발생했습니다.');
    }
  };

  if (loading) return <div className="dashboard-loading">로딩 중...</div>;
  if (error) return <div className="dashboard-error">{error}</div>;

  return (
    <div className="dashboard-container">
      {/* 헤더 */}
      <div className='wrap-side'>
        <header>
          <h1 className="dashboard-title">카드뉴스 대시보드</h1>
          <div className="download-controls mb-4">
            <button onClick={handleDownloadAll} className="download-all-button">
              선택된 카드 일괄 다운로드 ({filteredCards.length}개)
            </button>
          </div>
          </header>

        {/* 좌측 필터(개선) */}
        <aside className="filter-sidebar">
          <div className="filter-section">
            {/* <FilterSection
              title="시나리오"
              options={filterOptions.scenarios}
              currentValue={filters.scenario}
              onSelect={(value) => setFilters({...filters, scenario: value})}
            /> */}
            <FilterSection
              title="시나리오"
              options={filterOptions.scenarios}
              currentValue={filters.scenario}
              onSelect={(value) => setFilters({...filters, scenario: value})}
            />
            
            <FilterSection
              title="연령대"
              options={filterOptions.ages}
              currentValue={filters.age}
              onSelect={(value) => setFilters({...filters, age: value})}
            />
            
            <FilterSection
              title="성별"
              options={filterOptions.genders}
              currentValue={filters.gender}
              onSelect={(value) => setFilters({...filters, gender: value})}
            />
            
            <FilterSection
              title="생성일자"
              options={filterOptions.months}
              currentValue={filters.month}
              onSelect={(value) => setFilters({...filters, month: value})}
            />
            
            <FilterSection
              title="상태"
              options={filterOptions.statuses}
              currentValue={filters.status}
              onSelect={(value) => setFilters({...filters, status: value})}
            />
          </div>
        </aside>
      </div>
      {/* 카드 다운로드 */}
      {/* <div className="download-controls mb-4">
        <button 
          onClick={handleDownloadAll}
          className="download-all-button"
        >
          선택된 카드 일괄 다운로드 ({filteredCards.length}개)
        </button>
      </div> */}

      {/* 메인 컨텐츠 영역 - 기존 카드뉴스 부분 유지 */}
      <main className="main-content">


        {/* 카드뉴스 영역 */}
        <div className="cards-grid">
        {filteredCards.map((card, index) => (
          <div key={card.card_id} className="card-news-container">

            {/* 카드 id, 상태 */}
            <div className="badge-container">
              <div className="card-id-badge">
                  카드 ID: {card.card_id}
                </div>
                <div className={`status-badge ${card.status}`}>
                  {card.status}
                </div>
            </div>
            
            {/* 카드뉴스 이미지 영역(다운로드가능) */}
            <div 
              className="card-news-content"
              ref={el => cardRefs.current[index] = el}
            >
              <div className="card-text">
                {editingId === card.card_id ? (
                  <div className="edit-form">
                    <span>제목</span>
                    <input
                      type="text"
                      value={editData.title}
                      onChange={(e) => setEditData({ ...editData, title: e.target.value })}
                      className="edit-input title-input"
                      placeholder="제목"
                    />
                    <span>부제목</span>
                    <input
                      type="text"
                      value={editData.subtitle}
                      onChange={(e) => setEditData({ ...editData, subtitle: e.target.value })}
                      className="edit-input subtitle-input"
                      placeholder="부제목"
                    />
                    <span>설명</span>
                    <textarea
                      value={editData.description}
                      onChange={(e) => setEditData({ ...editData, description: e.target.value })}
                      className="edit-input description-input"
                      placeholder="설명"
                    />
                    <span>심의번호</span>
                    <input
                      type="text"
                      value={editData.approval_number}
                      onChange={(e) => setEditData({ ...editData, approval_number: e.target.value })}
                      className="edit-input approval-input"
                      placeholder="심의번호"
                    />
                    <div className="status-toggle-group">
                      <span className="status-label">상태</span>
                      <div className="status-buttons">
                        {statusOptions.map((status) => (
                          <button
                            key={status}
                            className={`status-button ${editData.status === status ? 'active' : ''}`}
                            onClick={() => handleStatusChange(status)}
                            type="button"
                          >
                            {status}
                          </button>
                        ))}
                      </div>
                    </div>
                    <div className="edit-buttons">
                      <button className="save-button" onClick={() => handleUpdate(card.card_id)}>
                        저장
                      </button>
                      <button className="cancel-button" onClick={handleCancelEdit}>
                        취소
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="card-title-db">{card.title}</div>
                    <div className="card-subtitle-db">{card.subtitle}</div>
                    <div className="card-image-db">
                      {/* <CardImage */}
                      <img
                        src={card.img_url}
                        alt={card.title}
                        className="generated-image"
                        crossOrigin="anonymous"
                      />
                    </div>
                    <div className='card-description-db-wrap'>
                      <p className="card-description-db">
                        {card.description.replace(/([?!])\s+/g, '$1\n')}
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="card-footer-db">
                <img 
                  src="/img/logo.png" 
                  alt="Company Logo" 
                  className="company-logo-db"
                  crossOrigin="anonymous"
                />
                <span className='approval-number'>심의번호 : {card.approval_number || 'N/A'}</span>
              </div>
            </div>
            {editingId !== card.card_id && (
              <div className="card-controls">
                  {editingId !== card.card_id ? (
                    <>
                      <button className="edit-button" onClick={() => handleEdit(card)}>
                        수정
                      </button>
                      <button 
                        className="download-button"
                        onClick={() => handleDownloadSingle(card.card_id, index)}
                      >
                        다운로드
                      </button>
                      <button 
                        className="regenerate-button"
                        onClick={() => handleRegenerateImage(card)}
                        disabled={regeneratingImage}
                      >
                        이미지 재생성
                      </button>
                    </>
                  ) : null}
                </div>
            
            
            )}
          </div>
        ))}
      </div>
      </main>

      {/* 이미지 재생성 모달창 */}
      <ImageSelectionModal
      isOpen={isImageModalOpen}
      onClose={handleCancelVariation}
      currentImage={currentImageUrl}
      variations={imageVariations}
      selectedVariation={selectedVariation}
      onSelect={handleSelectVariation}
      onApply={handleApplyVariation}
      cardId={activeCardId}
      cardInfo={currentCardInfo}  // 카드 정보 전달
    />

    </div>
  );

};

export default CardDashboard;