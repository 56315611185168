// FilterSection.js
import React, { useState } from 'react';

const FilterSection = ({ title, options, currentValue, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="filter-group">
      <button 
        className="filter-toggle-button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <span className={`toggle-icon ${isOpen ? 'open' : ''}`}>▼</span>
      </button>
      
      {isOpen && (
        <div className="filter-options">
          <button 
            className={`filter-option ${currentValue === 'all' ? 'selected' : ''}`}
            onClick={() => {
              onSelect('all');
            }}
          >
            {title} 전체
          </button>
          {options.map(option => (
            <button
              key={option}
              className={`filter-option ${currentValue === option ? 'selected' : ''}`}
              onClick={() => {
                onSelect(option);
              }}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterSection;