import React, { useState, useCallback, useRef, useEffect } from 'react';
import '../css/demo.css';

// 텍스트 처리 단계 정의 (상단에 추가)
const processSteps = [
  { id: 1, title: '텍스트 분석', status: 'pending' },
  { id: 2, title: '키워드 추출', status: 'pending' },
  { id: 3, title: '밀리 키워드 매칭', status: 'pending' }
];

const CardNewsDemo = () => {
  // 기본 상태 관리
  const [selectedCard, setSelectedCard] = useState(null); // 선택된 카드 상태 추가
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [scenario, setScenario] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedText, setGeneratedText] = useState(null);
  const [textProcessSteps, setTextProcessSteps] = useState([]);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [cardNews, setCardNews] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [isGeneratingCardNews, setIsGeneratingCardNews] = useState(false);


  // refs 추가
  const messageRef = useRef(null);
  const imageRef = useRef(null);
  const cardNewsRef = useRef(null);
  


  const scrollToBottom = (ref) => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (messages.length > 0) {
      setTimeout(() => scrollToBottom(messageRef), 100);
    }
  }, [messages]);
  
  useEffect(() => {
    if (generatedImages.length > 0) {
      setTimeout(() => scrollToBottom(imageRef), 100);
    }
  }, [generatedImages]);
  
  useEffect(() => {
    if (cardNews.length > 0) {
      setTimeout(() => scrollToBottom(cardNewsRef), 100);
    }
  }, [cardNews]);

  // 모달 열기 핸들러
  const handleCardClick = (cardUrl) => {
    setSelectedCard(cardUrl);
    document.body.style.overflow = 'hidden'; // 모달 열릴 때 스크롤 방지
  };

  // 모달 닫기 핸들러
  const handleCloseModal = () => {
    setSelectedCard(null);
    document.body.style.overflow = 'auto'; // 모달 닫힐 때 스크롤 복구
  };

  // 모달 컴포넌트
  const Modal = ({ imageUrl, onClose }) => {
    const handleModalClick = (e) => {
      if (e.target.classList.contains('modal-overlay')) {
        onClose();
      }
    };

    return (
      <div className="modal-overlay" onClick={handleModalClick}>
        <div className="modal-content">
          <img src={imageUrl} alt="확대된 카드뉴스" className="modal-image" />
        </div>
        <style jsx="true">{`
          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
            cursor: pointer;
          }
          
          .modal-content {
            max-width: 90vw;
            max-height: 90vh;
            cursor: default;
          }
          
          .modal-image {
            max-width: 100%;
            max-height: 90vh;
            object-fit: contain;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }
        `}</style>
      </div>
    );
  };



  // 텍스트 처리 단계 정의
  const processSteps = [
    { id: 1, title: '텍스트 생성', status: 'pending' },
    { id: 2, title: '텍스트 검증', status: 'pending' },
    { id: 3, title: '민감어휘 필터링', status: 'pending' }
  ];

  // 옵션 데이터
  const genderOptions = [
    { value: 'all', label: '모두 선택' },
    { value: 'male', label: '남성' },
    { value: 'female', label: '여성' },
  ];

  const ageOptions = [
    { value: 'all', label: '모두 선택' },
    { value: '30', label: '30대' },
    { value: '40', label: '40대' },
    { value: '50', label: '50대' },
    { value: '60', label: '60대' },
  ];

  const scenarioOptions = [
    // { value: 'greeting_fix_car', label: '안부 + 겨울철 대비 차량관리 Tip' },
    { value: 'greeting_xmas', label: '크리스마스 안부 인사' },
    // { value: 'greeting_safe_driving', label: '새해 맞이 인사 (안전운전)' },
  ];


  const scenarioMessages = {
    greeting_xmas: [
      {
        title: "따뜻한 성장을 나누는 크리스마스",
        subtitle: "함께하는 시간",
        description: "한 해 동안 쌓아온 성장과 성과를 돌아보며, 따뜻한 크리스마스를 보내시는 건 어떨까요? 새해에도 함께하길 기대합니다."
      },
      {
        title: "한 해의 결실을 나누는 시간",
        subtitle: "평화로운 연말",
        description: "한 해의 성과를 돌아보며, 따뜻한 시간 보내시길 바랍니다. 크리스마스의 온기로 한 해를 마무리해보시는 건 어떨까요?"
      },
      {
        title: "따뜻함을 더하는 크리스마스",
        subtitle: "소중한 순간",
        description: "크리스마스의 따뜻함을 가족과 나누며 의미 있는 연말 보내시는 건 어떨까요? 다가오는 새해에 더 큰 성공과 행복이 함께하길 기원합니다."
      },
      {
        title: "마음에 남을 따뜻함",
        subtitle: "함께 나누는 시간",
        description: "사랑과 건강을 챙기는 연말, 소중한 이들과 따뜻한 추억을 나누어 보시는 건 어떨까요? 행복한 크리스마스 되세요!"
      },
      {
        title: "마음을 채우는 계절",
        subtitle: "평안한 크리스마스",
        description: "온 가족과 함께 따뜻한 기억을 쌓으며 올해의 크리스마스를 평온하게 보내시는 건 어떠세요? 건강한 연말 되세요."
      },
      {
        title: "행복으로 채워지는 연말",
        subtitle: "따뜻한 시간",
        description: "한 해를 마무리하며 가족과 따뜻한 시간을 나누고 행복을 충전해 보세요. 사랑과 건강이 가득한 크리스마스 보내시길 바랍니다."
      },
      {
        title: "함께 나누는 따뜻함",
        subtitle: "기쁨이 깃든 순간",
        description: "따뜻한 마음이 전해지는 크리스마스, 사랑과 평화로 채워지는 시간 나누시는 건 어떨까요?"
      },
      {
        title: "크리스마스의 온기",
        subtitle: "소중한 시간 되세요",
        description: "가족과 함께하는 순간의 가치가 더욱 깊어지는 겨울, 따뜻한 나눔으로 크리스마스를 보내시는 건 어떨까요?"
      },
      {
        title: "올해도 함께한 감사",
        subtitle: "소망을 나누는 날",
        description: "다사다난했던 한 해를 돌아보며 따뜻한 마음을 나누는 크리스마스, 잔잔한 기쁨으로 소망을 나누시는 건 어떨까요?"
      },
      {
        title: "따스한 크리스마스, 어떠세요?",
        subtitle: "사랑과 온기 나누기",
        description: "가족과 함께 따뜻한 순간을 만들어보시는 건 어떨까요? 사랑 가득한 크리스마스 되시길 바랍니다."
      },
      {
        title: "평온함 속 크리스마스",
        subtitle: "소중한 사람과 함께",
        description: "한 해 동안의 소중한 기억들을 떠올리며, 사랑하는 이들과 따뜻한 시간 나누시는 건 어떨까요?"
      },
      {
        title: "따뜻한 추억을 쌓아보세요",
        subtitle: "사랑의 온도 높이기",
        description: "작은 온기와 배려가 모여 큰 행복이 됩니다. 가족과 함께 따뜻한 크리스마스를 보내시길 바랍니다."
      }
    ],
    greeting_fix_car: [
      {
        title: "안전한 겨울 운전, 함께해요",
        subtitle: "차량 점검 꿀팁",
        description: "겨울철 안전운전을 위한 필수 점검사항을 알려드립니다. 안전한 주행 되세요!"
      },
      {
        title: "겨울철 차량관리 체크리스트",
        subtitle: "안전한 주행을 위한 준비",
        description: "부동액 점검부터 배터리 상태까지, 꼼꼼한 차량 점검으로 안전운전 하세요."
      },
      {
        title: "당신의 안전한 운전을 응원합니다",
        subtitle: "겨울철 특별 관리 포인트",
        description: "추운 날씨, 안전한 차량 관리로 편안한 주행하세요. 안전운전이 가장 중요합니다."
      }
    ],
    greeting_safe_driving: [
      {
        title: "새해, 안전운전으로 시작해요",
        subtitle: "안전한 출발",
        description: "새해에도 안전운전 잊지 마세요. 건강하고 행복한 한 해 되시길 바랍니다."
      },
      {
        title: "2024년, 안전운전 다짐해요",
        subtitle: "새로운 시작",
        description: "새해를 맞아 안전운전을 다짐해보세요. 당신의 안전한 운전이 모두의 행복입니다."
      },
      {
        title: "행복한 새해, 안전한 운전",
        subtitle: "함께하는 안전",
        description: "새해에도 변함없이 안전운전 실천하시는 당신을 응원합니다. 새해 복 많이 받으세요!"
      }
    ]
  };


const handleTextGeneration = useCallback(() => {
  if (gender && age && scenario) {
    setIsGenerating(true);
    setTextProcessSteps([]);
    setMessages([]);

    const currentMessages = scenarioMessages[scenario];
    
    // 처리 단계를 순차적으로 표시
    processSteps.forEach((step, i) => {
      setTimeout(() => {
        setTextProcessSteps(prev => {
          const newSteps = [...prev];
          newSteps.push({
            ...processSteps[i],
            status: 'completed'
          });
          return newSteps;
        });

        // 마지막 단계(민감어휘 필터링) 완료 후
        if (i === processSteps.length - 1) {
          // 1.5초 후에 메시지 순차적 표시 시작
          setTimeout(() => {
            currentMessages.forEach((message, messageIndex) => {
              setTimeout(() => {
                setMessages(prev => {
                  const newMessages = [...prev, message];
                  // 각 메시지가 추가된 후 스크롤
                  setTimeout(() => scrollToBottom(messageRef), 100);
                  return newMessages;
                });
                if (messageIndex === currentMessages.length - 1) {
                  setIsGenerating(false);
                }
              }, messageIndex * 1000); // 각 메시지는 1초 간격으로 표시
            });
          }, 1500); // 필터링 완료 후 1.5초 대기
        }
      }, (i + 1) * 1000); // 처리 단계는 1초 간격
    });
  }
}, [gender, age, scenario]);




  // 이미지 생성 처리
const handleImageGeneration = useCallback(() => {
  if (messages.length === 12) {
    setIsGenerating(true);
    setIsGeneratingImages(true);  // 추가
    setGeneratedImages([]);
    
    // 시나리오별 이미지 경로
    const getImagePath = (index) => {
      switch(scenario) {
        case 'greeting_xmas':
          return `/images/xmas/image-${index}.png`;
        case 'greeting_fix_car':
          return `/images/car/img_${index}.jpg`;
        case 'greeting_safe_driving':
          return `/images/newyear/img_${index}.jpg`;
        default:
          return `/images/default/img_${index}.jpg`;
      }
    };
    
    // 9개의 이미지를 순차적으로 표시
    for (let i = 1; i <= 24; i++) {
      setTimeout(() => {
        setGeneratedImages(prev => [...prev, getImagePath(i)]);
        if (i === 24) {
          setIsGenerating(false);
          setIsGeneratingImages(false);  // 추가
        }
      }, i * 1500);
    }
  }
}, [messages, scenario]);


// 카드뉴스 생성 처리
const handleCardNewsGeneration = useCallback(() => {
  if (generatedImages.length >= 9) {
    setIsGenerating(true);
    setIsGeneratingCardNews(true);  // 추가
    setCardNews([]);
    
    // 시나리오별 카드뉴스 경로
    const getCardPath = (index) => {
      switch(scenario) {
        case 'greeting_xmas':
          return `/images/xmas_card/card-${index}.png`;
        case 'greeting_fix_car':
          return `/images/car_card/card-${index}.png`;
        case 'greeting_safe_driving':
          return `/images/newyear_card/card-${index}.png`;
        default:
          return `/images/default_card/card-${index}.png`;
      }
    };
    
    // 카드뉴스 이미지를 순차적으로 표시
    for (let i = 1; i <= 32; i++) {
      setTimeout(() => {
        setCardNews(prev => [...prev, getCardPath(i)]);
        if (i === 32) {
          setIsGenerating(false);
          setIsGeneratingCardNews(false);  // 추가
        }
      }, i * 2000);
    }
  }
}, [generatedImages.length, scenario]);


  // 옵션 선택 컴포넌트
  const OptionSelector = ({ label, options, value, onChange }) => (
    <div className="persona-section">
      <h4>{label}</h4>
      <select 
        value={value} 
        onChange={(e) => onChange(e.target.value)}
        className="persona-dropdown"
      >
        <option value="">선택하세요</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );

  return (
    <div className="App">
      <header className="App-header">
        <h1>페르소나 기반 고객 맞춤형 AI 컨텐츠 생성 솔루션</h1>
      </header>

      <div className="App-columns">
        {/* 페르소나 설정 컬럼 */}
        <div className="App-column">
          <h2>Process 1</h2>
          <h3>페르소나 설정</h3>
          
          <div className="column-content">
            <OptionSelector 
              label="성별" 
              options={genderOptions} 
              value={gender} 
              onChange={setGender}
            />
            <OptionSelector 
              label="연령대" 
              options={ageOptions} 
              value={age} 
              onChange={setAge}
            />
            <OptionSelector 
              label="시나리오" 
              options={scenarioOptions} 
              value={scenario} 
              onChange={setScenario}
            />
          </div>

          <button
            onClick={handleTextGeneration}
            disabled={!gender || !age || !scenario || isGenerating}  // 버튼 활성화 조건 수정
            className="action-button"
          >
            텍스트 생성하기
          </button>
        </div>

        {/* 텍스트 생성 결과 컬럼 */}
        <div className="App-column">
        <h2>Process 2</h2>
        <h3>AI 텍스트 생성</h3>
        
        <div className="column-content">
            <div className="process-steps">
              {textProcessSteps.map((step, index) => (
                <div key={index} className="process-step">
                  <div className="step-number">{step.id}</div>
                  <div className="step-title">{step.title}</div>
                  <div className="step-status">완료</div>
                </div>
              ))}
            </div>
            
            <div className="result-container message-container" ref={messageRef} style={{ maxHeight: "35vh", background: "#FFFFFF"}}>
              {messages.map((message, index) => (
                <div key={index} className="message-section">
                  <h4 className="message-number">메시지 {index + 1}</h4>
                  <div className="message-content">
                    <p><strong>제목:</strong> {message.title}</p>
                    <p><strong>부제목:</strong> {message.subtitle}</p>
                    <p><strong>설명문:</strong> {message.description}</p>
                  </div>
                  {index < messages.length - 1 && <hr />}
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={handleImageGeneration}
            disabled={messages.length < 6 || isGenerating}
            className="action-button"
          >
            이미지 생성하기
          </button>
        </div>

        {/* 이미지 생성 결과 컬럼 */}
        <div className="App-column">
          <h2>Process 3</h2>
          {/* <h3>이미지 생성</h3> */}
          <h3 className={isGeneratingImages ? 'processing-title' : ''}>
    {isGeneratingImages ? 'AI 이미지 생성 중' : 'AI 이미지 생성'}
  </h3>
          
          <div className="column-content">
      {generatedImages.length > 0 && (
        <div className="result-container" ref={imageRef}>
          <h4>생성된 이미지</h4>
          <div className="image-grid">
            {generatedImages.map((image, index) => (
              <div key={index} className="image-item">
                <img
                  src={image}
                  alt={`Generated ${index + 1}`}
                  className="grid-image"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/images/placeholder.jpg'; // 이미지 로드 실패시 대체 이미지
                  }}
                />
                <span className="image-number">{index + 1}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>

    <button
      onClick={handleCardNewsGeneration}
      disabled={generatedImages.length < 9 || isGenerating}
      className="action-button"
    >
      카드뉴스 생성하기
    </button>
  </div>

        {/* 카드뉴스 결과 컬럼 */}
        <div className="App-column">
          <h2>Process 4</h2>
          <h3 className={isGeneratingCardNews ? 'processing-title' : ''}>
            {isGeneratingCardNews ? '카드뉴스 생성 중' : '카드뉴스 결과'}
          </h3>
          
          <div className="column-content">
            {cardNews.length > 0 && (
              <div className="result-container" ref={cardNewsRef} style={{ maxHeight: "65vh"}}>
                <h4>생성된 카드뉴스</h4>
                <div className="card-news-gallery">
                  {cardNews.map((card, index) => (
                    <div 
                      key={index} 
                      className="card-item"
                      onClick={() => handleCardClick(card)}
                    >
                      <img
                        src={card}
                        alt={`Card News ${index + 1}`}
                        className="card-news-image"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/images/placeholder.jpg';
                        }}
                      />
                      <span className="card-number">카드 {index + 1}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* 모달 렌더링 */}
      {selectedCard && (
        <Modal
          imageUrl={selectedCard}
          onClose={handleCloseModal}
        />
      )}

      <style jsx="true">{`
        .card-item {
          position: relative;
          width: 45%;
          margin: 8px;
          cursor: pointer;
          transition: transform 0.2s ease-in-out;
        }

        .card-item:hover {
          transform: scale(1.05);
        }

        .card-news-image {
          width: 100%;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .card-number {
          position: absolute;
          top: 8px;
          left: 8px;
          background: rgba(0, 0, 0, 0.6);
          color: white;
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
        }
      `}</style>
    </div>
  );
};

export default CardNewsDemo;