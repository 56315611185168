import React, { useState, useEffect } from 'react';
// import './App.css';
import '../css/App.css';

const apiUrl = process.env.REACT_APP_API_URL;
const loadingGifUrl = '/img/spinner_tutor_01.gif';

function App() {
  // 각 프로세스의 결과를 저장하는 상태
  const [process1Result, setProcess1Result] = useState(null);
  const [process2Result, setProcess2Result] = useState(null);
  const [process3Result, setProcess3Result] = useState(null);
  
  // 사용자 케이스와 선택된 옵션들을 저장하는 상태
  const [userCase, setUserCase] = useState(null);
  const [selectedAge, setSelectedAge] = useState('');
  const [selectedHousehold, setSelectedHousehold] = useState('');
  const [selectedJob, setSelectedJob] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedScenario, setSelectedScenario] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  
  // 로딩 상태와 에러 메시지를 저장하는 상태
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // 선택된 이미지의 URL을 저장하는 상태
  const [selectedImage, setSelectedImage] = useState(null);

  // 컴포넌트가 마운트될 때 사용자 케이스를 가져오는 효과
  useEffect(() => {
    const fetchUserCase = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${apiUrl}persona-content-gen-api/get_user_case`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        });
        
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        
        const data = await response.json();
        console.log(`user case:`, data);
        setUserCase(data);
      } catch (e) {
        console.error("Fetch error:", e);
        setError(`사용자 케이스를 가져오는데 실패했습니다: ${e.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchUserCase();
  }, []);

  // 각 프로세스를 처리하는 함수
  const handleProcess = async (processName, item = null) => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(`${apiUrl}persona-content-gen-api/${processName}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(item),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      console.log(`Process ${processName} result:`, data);

      if (data.status === 400) {
        throw new Error(data.error);
      }

      // 프로세스 이름에 따라 적절한 상태를 업데이트
      switch (processName) {
        case 'generate_text':
          setProcess1Result(data);
          break;
        case 'generate_image':
          setProcess2Result(data);
          break;
        case 'generate_result':
          setProcess3Result(data);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Process error:", error);
      setError(`프로세스 ${processName} 실행 중 오류 발생: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // 드롭다운 렌더링 함수
  const renderDropdown = (title, options, value, onChange) => (
    <div className="persona-section">
      <h4 style={{'marginBottom': '5px', 'marginTop':'15px'}}>{title}</h4>
      <select 
        value={value} 
        onChange={(e) => onChange(e.target.value)}
        className="persona-dropdown"
      >
        <option value="">선택하세요</option>
        {options && options.map((option) => (
          <option key={option} value={option}>
            {option === 'male' ? '남성' : option === 'female' ? '여성' : option}
          </option>
        ))}
      </select>
    </div>
  );

  // ImageGallery 컴포넌트
  const ImageGallery = ({ images }) => {
    const toggleModal = (imageData) => {
      setSelectedImage(selectedImage === imageData ? null : imageData);
    };
  
    return (
      <div className="image-gallery">
        {images.map((imageData, index) => (
          <img
            key={index}
            src={imageData}
            alt={`Generated Image ${index + 1}`}
            className="gallery-image"
            onClick={() => toggleModal(imageData)}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>페르소나 기반 AI 맞춤 컨텐츠 생성 프로세스</h1>
      </header>

      {error && <div className="error-message">{error}</div>}

      <div className="App-columns">
        {/* 페르소나 설정 */}
        <div className="App-column">
          <h2>Process 1</h2>
          <h3>페르소나 설정</h3>
          <button
            onClick={() =>
              handleProcess('generate_text', {
                age: selectedAge,
                household: selectedHousehold,
                job: selectedJob,
                region: selectedRegion,
                scenario: selectedScenario,
                gender: selectedGender,
              })
            }
            disabled={!selectedAge || !selectedHousehold || !selectedJob || !selectedRegion || !selectedScenario || !selectedGender || loading}
          >
            텍스트 생성하기
          </button>
          <div className="column-content">
            {userCase ? (
              <>
                {renderDropdown('연령대', userCase.age, selectedAge, setSelectedAge)}
                {renderDropdown('가구 형태', userCase.household, selectedHousehold, setSelectedHousehold)}
                {renderDropdown('직업', userCase.job, selectedJob, setSelectedJob)}
                {renderDropdown('지역', userCase.region, selectedRegion, setSelectedRegion)}
                {renderDropdown('시나리오', userCase.scenario, selectedScenario, setSelectedScenario)}
                {renderDropdown('성별', userCase.gender, selectedGender, setSelectedGender)}
              </>
            ) : (
              <p>사용자 케이스 데이터를 불러오는 중...</p>
            )}
          </div>
          {loading && (
            <div className="loading-modal">
              <img src={loadingGifUrl} alt="로딩 중..." className="loading-gif" />
            </div>
          )}
        </div>

        {/* 텍스트 생성 */}
        <div className="App-column">
          <h2>Process 2</h2>
          <h3>텍스트 생성</h3>
          <button 
            onClick={() => handleProcess('generate_image', {
              ...process1Result,
              age: selectedAge,
              household: selectedHousehold,
              job: selectedJob,
              region: selectedRegion,
              scenario: selectedScenario,
              gender: selectedGender,
            })} 
            disabled={!process1Result || loading}
          >
            이미지 생성하기
          </button>
          <div className="column-content">
            {process1Result && (
              <div className="result-container">
                <h4>생성된 텍스트</h4>
                <div className="result-section">
                  <h5>주요 문장:</h5>
                  <p>{process1Result.key_phrase || process1Result.text_output?.key_phrase || '데이터 없음'}</p>
                </div>
                <div className="result-section">
                  <h5>설명문:</h5>
                  <p>{process1Result.context || process1Result.text_output?.context || '데이터 없음'}</p>
                </div>
              </div>
            )}
          </div>
          {loading && (
            <div className="loading-modal">
              <img src={loadingGifUrl} alt="로딩 중..." className="loading-gif" />
            </div>
          )}
        </div>
                
        {/* 이미지 생성 */}
        <div className="App-column">
          <h2>Process 3</h2>
          <h3>이미지 생성</h3>
          <button 
            onClick={() => handleProcess('generate_result', {
              ...process1Result,
            })} 
            disabled={!process1Result || loading}
          >
            카드 뉴스 생성
          </button>
          <div className="column-content">
            {process2Result && process2Result.image_output && (
              <div className="result-container">
                <h4>생성된 이미지</h4>
                <ImageGallery images={process2Result.image_output} />
              </div>
            )}
          </div>
          {loading && (
            <div className="loading-modal">
              <img src={loadingGifUrl} alt="로딩 중..." className="loading-gif" />
            </div>
          )}
        </div>

        {/* 텍스트 & 이미지 조합 */}
        <div className="App-column">
          <h2>Process 4</h2>
          <h3>카드 뉴스 생성</h3>
          {/* <button disabled={!process3Result || loading}>Process 4 실행</button> */}
          <div className="column-content">
            {process3Result &&  process3Result.result_files && (
              <div className="result-container">
                <h4>카드 뉴스</h4>
                <ImageGallery images={process3Result.result_files} />
              </div>
            )}
          </div>
          {loading && (
            <div className="loading-modal">
              <img src={loadingGifUrl} alt="로딩 중..." className="loading-gif" />
            </div>
          )}
        </div>
      </div>

      {selectedImage && (
        <div className="modal" onClick={() => setSelectedImage(null)}>
          <img
            src={selectedImage}
            alt="Selected Image"
            className="modal-image"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </div>
  );
}

export default App;