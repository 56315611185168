import React, { useState, useEffect } from 'react';
import { Edit2, Save, Link, PlusCircle, Trash2 } from 'lucide-react';
import axios from 'axios';
import '../css/scenario_management.css';
import LoadingOverlay from './LoadingOverlay';

const ScenarioManagement = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [scenarios, setScenarios] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('news');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [createFormData, setCreateFormData] = useState({
    scenario_case_ko: '',
    source: '[]'
  });
  const [editMode, setEditMode] = useState({
    news_data: false,
    eg_text: false,
    common_prompt: false
  });
  const [editFormData, setEditFormData] = useState({
    news_data: '',
    eg_text: '',
    common_prompt: ''
  });

  useEffect(() => {
    fetchScenarios();
  }, []);

  const fetchScenarios = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/persona-content-gen-api/api/scenarios`);
      setScenarios(response.data);
      if (response.data.length > 0 && !selectedScenario) {
        setSelectedScenario(response.data[0]);
        setEditFormData({
          news_data: response.data[0].news_data,
          eg_text: response.data[0].eg_text,
          common_prompt: response.data[0].common_prompt
        });
      }
    } catch (err) {
      setError('시나리오 데이터를 불러오는데 실패했습니다.');
      console.error('Error fetching scenarios:', err);
    } finally {
      setLoading(false);
    }
  };

  // const handleCreateScenario = async (e) => {
  //   e.preventDefault();
  //   setIsSubmitting(true);
  //   try {
  //     await axios.post(`${apiUrl}/persona-content-gen-api/api/scenarios`, createFormData);
  //     await fetchScenarios();
  //     setShowCreateForm(false);
  //     setCreateFormData({
  //       scenario_case_ko: '',
  //       scenario_case_en: '',
  //       news_data: '',
  //       eg_text: '',
  //       common_prompt: '',
  //       source: '[]'
  //     });
  //   } catch (err) {
  //     alert('시나리오 생성에 실패했습니다.');
  //     console.error('Error creating scenario:', err);
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };
  const handleCreateScenario = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(`${apiUrl}/persona-content-gen-api/api/scenarios`, createFormData);
      await fetchScenarios();
      setShowCreateForm(false);
      setCreateFormData({
        scenario_case_ko: '',
        scenario_case_en: '',
        news_data: '',
        eg_text: '',
        common_prompt: '',
        source: '[]'
      });
    } catch (err) {
      alert('시나리오 생성에 실패했습니다.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteScenario = async (scenarioId) => {
    if (!window.confirm('정말 이 시나리오를 삭제하시겠습니까?')) return;
    
    try {
      await axios.delete(`${apiUrl}/persona-content-gen-api/api/scenarios/${scenarioId}`);
      if (selectedScenario?.scenario_id === scenarioId) {
        setSelectedScenario(null);
      }
      await fetchScenarios();
    } catch (err) {
      alert('시나리오 삭제에 실패했습니다.');
      console.error('Error deleting scenario:', err);
    }
  };

  const handleScenarioSelect = (scenario) => {
    setSelectedScenario(scenario);
    setEditFormData({
      news_data: scenario.news_data,
      eg_text: scenario.eg_text,
      common_prompt: scenario.common_prompt
    });
    setEditMode({
      news_data: false,
      eg_text: false,
      common_prompt: false
    });
  };

  const handleEdit = (field) => {
    setEditMode(prev => ({ ...prev, [field]: true }));
  };

  const handleSave = async (field) => {
    try {
      await axios.patch(`${apiUrl}/persona-content-gen-api/api/scenarios/${selectedScenario.scenario_id}`, {
        [field]: editFormData[field]
      });
      setEditMode(prev => ({ ...prev, [field]: false }));
      setSelectedScenario(prev => ({
        ...prev,
        [field]: editFormData[field]
      }));
      await fetchScenarios();
    } catch (err) {
      alert('저장에 실패했습니다.');
      console.error('Error updating scenario:', err);
    }
  };

  const renderSourceLinks = (sourceData) => {
    try {
      const sources = typeof sourceData === 'string' ? JSON.parse(sourceData) : sourceData;
      return sources.map((source, index) => (
        <div key={index} className="source-item">
          <a href={source.url} target="_blank" rel="noopener noreferrer" className="source-title">
            {source.title}
          </a>
          <span className="source-score">Score: {source.score.toFixed(4)}</span>
        </div>
      ));
    } catch {
      return <div className="error-message">소스 데이터 형식이 올바르지 않습니다.</div>;
    }
  };

  const renderEditableField = (field, value, label) => {
    const isEditing = editMode[field];
    return (
      <div className="editable-field">
        <div className="field-header">
          <h3>{label}</h3>
          <button
            className={`edit-button ${isEditing ? 'active' : ''}`}
            onClick={() => isEditing ? handleSave(field) : handleEdit(field)}
          >
            {isEditing ? (
              <><Save className="icon" /> 저장</>
            ) : (
              <><Edit2 className="icon" /> 수정</>
            )}
          </button>
        </div>
        <div className="field-content">
          {isEditing ? (
            <textarea
              value={editFormData[field]}
              onChange={(e) => setEditFormData(prev => ({ ...prev, [field]: e.target.value }))}
            />
          ) : (
            <div className="content-display">{value}</div>
          )}
        </div>
      </div>
    );
  };

  const renderCreateForm = () => {
    return (
      <div className="create-form">
        <h2>새 시나리오 생성</h2>
        <form onSubmit={handleCreateScenario}>
          <div className="form-group">
            <label>시나리오 이름</label>
            <input
              type="text"
              value={createFormData.scenario_case_ko}
              onChange={(e) => setCreateFormData(prev => ({
                ...prev,
                scenario_case_ko: e.target.value
              }))}
              required
            />
          </div>
          <div className="form-actions">
            <button type="submit" className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? '생성 중...' : '생성'}
            </button>
            <button 
              type="button" 
              className="cancel-button" 
              onClick={() => setShowCreateForm(false)}
            >
              취소
            </button>
          </div>
        </form>
      </div>
    );
  };

  if (loading) return <div className="loading">로딩 중...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="scenario-container">
      <LoadingOverlay isLoading={isSubmitting} />
      <div className="scenario-sidebar">
        <div className="sidebar-header">
          <h2>시나리오 목록</h2>
          <button 
            className="create-button"
            onClick={() => setShowCreateForm(true)}
          >
            <PlusCircle className="icon" /> 새 시나리오
          </button>
        </div>
        <div className="scenario-list">
          {scenarios.map((scenario) => (
            <div
              key={scenario.scenario_id}
              className={`scenario-item ${selectedScenario?.scenario_id === scenario.scenario_id ? 'active' : ''}`}
            >
              <div 
                className="scenario-info"
                onClick={() => handleScenarioSelect(scenario)}
              >
                <div className="scenario-name">{scenario.scenario_case_ko}</div>
                <div className="scenario-name-en">{scenario.scenario_case_en}</div>
              </div>
              <button
                className="delete-button"
                onClick={() => handleDeleteScenario(scenario.scenario_id)}
              >
                <Trash2 className="icon" />
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="content-area">
        {showCreateForm ? (
          renderCreateForm()
        ) : selectedScenario ? (
          <>
            <div className="content-header">
              <h1>{selectedScenario.scenario_case_ko}</h1>
              <p>{selectedScenario.scenario_case_en}</p>
            </div>

            <div className="tab-navigation">
              <button
                className={`tab-button ${activeTab === 'news' ? 'active' : ''}`}
                onClick={() => setActiveTab('news')}
              >
                뉴스 데이터
              </button>
              <button
                className={`tab-button ${activeTab === 'sources' ? 'active' : ''}`}
                onClick={() => setActiveTab('sources')}
              >
                소스
              </button>
              <button
                className={`tab-button ${activeTab === 'example' ? 'active' : ''}`}
                onClick={() => setActiveTab('example')}
              >
                프롬프트
              </button>
            </div>

            <div className="tab-content">
              {activeTab === 'news' && renderEditableField('news_data', selectedScenario.news_data, '뉴스 데이터')}
              {activeTab === 'sources' && (
                <div className="sources-list">
                  <h3>소스 목록</h3>
                  {renderSourceLinks(selectedScenario.source)}
                </div>
              )}
              {activeTab === 'example' && (
                <>
                  {renderEditableField('eg_text', selectedScenario.eg_text, '예시 데이터')}
                  {renderEditableField('common_prompt', selectedScenario.common_prompt, '공통 프롬프트')}
                </>
              )}
            </div>
          </>
        ) : (
          <div className="no-selection">시나리오를 선택해주세요</div>
        )}
      </div>
    </div>
  );
};

export default ScenarioManagement;