import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/card_news_reserve.css';

// API URL 상수 정의
const API_BASE_URL = 'https://persona-content-gen.o2o.kr';

// 이미지 스타일 옵션 상수 추가
const IMAGE_STYLE_OPTIONS = [
  { value: "watercolor drawing", label: "수채화 드로잉" },
  { value: "cartoon style illustration", label: "카툰 스타일 일러스트" },
  { value: "flat design infographic", label: "플랫 디자인 인포그래픽" },
  { value: "paper cutout style", label: "종이 오리기 스타일" },
  { value: "watercolor storybook", label: "수채화 동화책" },
  { value: "3D isometric style", label: "3D 아이소메트릭 스타일" },
  { value: "pixel art", label: "픽셀 아트" },
  { value: "pop art watercolor", label: "팝 아트 수채화" },
  { value: "flat minimal design", label: "플랫 미니멀 디자인" },
  { value: "low-poly illustration", label: "로우 폴리 일러스트" }
];

// 이미지 모달 컴포넌트 추가
const ImageModal = ({ imageUrl, onClose }) => {
  const handleModalClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleModalClick}>
      <div className="modal-content">
        <img src={imageUrl} alt="미리보기" className="modal-image" />
        <button className="modal-close-button" onClick={onClose}>닫기</button>
      </div>
    </div>
  );
};

// 날짜 포맷팅 함수 추가
const formatDate = (dateStr) => {
  try {
    // 서버에서 오는 날짜 문자열을 파싱 (예: "2024-03-27 05:17:30")
    const [date, time] = dateStr.split(' ');
    const [year, month, day] = date.split('-');
    const [hour, minute, second] = time.split(':');
    
    const formattedDate = new Date(year, month - 1, day, hour, minute, second);
    return formattedDate.toLocaleString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  } catch (e) {
    console.error('날짜 파싱 오류:', e);
    return dateStr; // 파싱 실패시 원본 문자열 반환
  }
};

const CardNewsReserve = () => {
  // 상태 관리
  const [formData, setFormData] = useState({
    age: '',
    gender: '',
    scenario: '',
    card_count: 1,
    customer_characteristics: '',
    image_style: ''
  });

  const [processList, setProcessList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [scenarios, setScenarios] = useState([]);
  const [scenarioOptions, setScenarioOptions] = useState([]);
  const [scenarioId, setScenarioId] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const previewImageUrl = "/img/preview.svg"; // 미리보기 이미지 URL

  // 시나리오 목록 조회
  const fetchScenarios = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/persona-content-gen-api/api/scenarios`);
      console.log('시나리오 응답:', response.data);
      
      if (Array.isArray(response.data)) {
        setScenarios(response.data);
        
        const options = response.data.map(scenario => ({
          value: scenario.scenario_case_en,
          label: scenario.scenario_case_ko,
          id: scenario.scenario_id
        }));
        
        setScenarioOptions(options);
        console.log("생성된 시나리오 옵션:", options);
      } else {
        console.error('시나리오 데이터가 배열이 아님:', response.data);
        setError('시나리오 데이터 형식이 올바르지 않습니다.');
      }
    } catch (err) {
      console.error('시나리오 데이터 로드 실패:', err);
      setError('시나리오 데이터를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  // 컴포넌트 마운트 시 시나리오 목록 조회
  useEffect(() => {
    fetchScenarios();
  }, []);

  // 폼 데이터 변경 핸들러
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // 시나리오 선택 핸들러 수정
  const handleScenarioChange = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = scenarioOptions.find(option => option.value === selectedValue);
    
    if (selectedOption) {
      setFormData(prev => ({
        ...prev,
        scenario: selectedOption.value
      }));
      setScenarioId(selectedOption.id);
      console.log('선택된 시나리오:', selectedOption); // 디버깅용 로그
    }
  };

  // 예약 생성 요청
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      // 요청 데이터 구성
      const requestData = {
        age: formData.age,
        gender: formData.gender,
        scenario: formData.scenario,
        scenario_id: scenarioId,  // scenarioId 추가
        card_count: parseInt(formData.card_count),
        customer_characteristics: formData.customer_characteristics,
        image_style: formData.image_style
      };

      console.log('예약 생성 요청 데이터:', requestData); // 디버깅용 로그

      const response = await axios.post(
        `${API_BASE_URL}/persona-content-gen-api/api/scheduled-generation`,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('예약 생성 응답:', response.data); // 디버깅용 로그

      if (response.data.status === 'success') {
        setSuccess('예약 생성이 등록되었습니다.');
        fetchProcessList(); // 목록 갱신
      } else {
        setError(response.data.message || '예약 생성에 실패했습니다.');
      }
    } catch (err) {
      console.error('예약 생성 오류:', err); // 디버깅용 로그
      setError(err.response?.data?.error || '예약 생성 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  // 프로세스 목록 조회
  const fetchProcessList = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/persona-content-gen-api/api/monitor-scheduled-generation`);
      console.log('서버 응답:', response.data); // 데이터 구조 확인용 로그

      if (response.data.requests) {
        const processData = response.data.requests.map(request => ({
          request_no: request.request_no,
          scenario_case_ko: request.processes[0]?.scenario_case_ko || '',
          overall_progress: request.overall_progress,
          progress_percentage: Math.round((request.completed_cards / request.total_cards) * 100) || 0,
          created_at: request.processes[0]?.created_at || ''
        }));
        setProcessList(processData);
      }
    } catch (err) {
      console.error('프로세스 목록 조회 실패:', err);
      setError('프로세스 목록을 불러오는데 실패했습니다.');
    }
  };

  // 1분 주기로 목록 갱신
  useEffect(() => {
    fetchProcessList();
    const interval = setInterval(fetchProcessList, 60000);
    return () => clearInterval(interval);
  }, []);

  // OptionSelector 컴포넌트 수정
  const OptionSelector = ({ label, options, value, onChange }) => (
    <div className="persona-section">
      <h4>{label}</h4>
      <select 
        value={value} 
        onChange={onChange}
        className="persona-dropdown"
      >
        <option value="">선택하세요</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );

  return (
    <div className="card-news-reserve-container">
      <div className="card-news-reserve-grid">
        {/* 좌측: 페르소나 입력 폼 */}
        <div className="persona-form-section">
          <h2 className="persona-form-title">페르소나 설정</h2>
          
          <form className="persona-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label className="form-label">나이대</label>
                <select
                  className="form-select"
                  name="age"
                  value={formData.age}
                  onChange={handleChange}
                  required
                >
                  <option value="">선택하세요</option>
                  <option value="30">30대</option>
                  <option value="40">40대</option>
                  <option value="50">50대</option>
                  <option value="60">60대</option>
                </select>
              </div>

              <div className="form-group">
                <label className="form-label">성별</label>
                <select
                  className="form-select"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  required
                >
                  <option value="">선택하세요</option>
                  <option value="male">남성</option>
                  <option value="female">여성</option>
                </select>
              </div>
            </div>

            <OptionSelector 
              label="시나리오" 
              options={scenarioOptions} 
              value={formData.scenario} 
              onChange={handleScenarioChange}
            />

            <div className="form-row">
              <div className="form-group">
                <label className="form-label">생성 횟수</label>
                <input
                  type="number"
                  className="form-input"
                  name="card_count"
                  value={formData.card_count}
                  onChange={handleChange}
                  min="1"
                  max="10"
                  required
                />
              </div>

              <div className="form-group">
                <label className="form-label">이미지 스타일</label>
                <select
                  className="form-select"
                  name="image_style"
                  value={formData.image_style}
                  onChange={handleChange}
                >
                  <option value="">선택하세요</option>
                  {IMAGE_STYLE_OPTIONS.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <button 
                  type="button"
                  className="preview-button"
                  onClick={() => setIsPreviewOpen(true)}
                >
                  스타일 미리보기
                </button>
              </div>
            </div>

            <div className="form-group">
              <label className="form-label">고객 특성</label>
              <textarea
                className="form-textarea"
                name="customer_characteristics"
                value={formData.customer_characteristics}
                onChange={handleChange}
              />
            </div>

            <button
              type="submit"
              className="submit-button"
              disabled={loading}
            >
              {loading && <span className="loading-spinner" />}
              예약 생성
            </button>
          </form>

          {error && (
            <div className="alert alert-error">
              {error}
            </div>
          )}
          {success && (
            <div className="alert alert-success">
              {success}
            </div>
          )}
        </div>

        {/* 우측: 예약 현황 모니터링 */}
        <div className="monitoring-section">
          <h2 className="monitoring-title">예약 현황</h2>

          <table className="monitoring-table">
            <thead>
              <tr>
                <th>요청번호</th>
                <th>시나리오</th>
                <th>상태</th>
                <th>진행률</th>
                <th>요청시간</th>
              </tr>
            </thead>
            <tbody>
              {processList.map((process) => (
                <tr key={process.request_no}>
                  <td>{process.request_no}</td>
                  <td>{process.scenario_case_ko}</td>
                  <td>
                    <span className={`status-${process.overall_progress}`}>
                      {process.overall_progress}
                    </span>
                  </td>
                  <td>{process.progress_percentage}%</td>
                  <td>{formatDate(process.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* 이미지 미리보기 모달 */}
      {isPreviewOpen && (
        <ImageModal
          imageUrl={previewImageUrl}
          onClose={() => setIsPreviewOpen(false)}
        />
      )}
    </div>
  );
};

export default CardNewsReserve;